import React from "react"
import Layout from "../components/Layout";
import "../components/privacy.sass";
import StaticWidthImage from "../components/StaticWidthImage";
import "../components/all.sass";
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {

  return (
    <>
      <Layout>
        <StaticWidthImage />
        <section >
          <div >
            <div >
              <div >
                {/* <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns"> */}
                <div className="column is-12 ">
                  <div className="content">

                    <div className="content"
                      style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                      }}
                    >
                      <div>
                        <h2 style={{textAlign:"center"}}>経営理念</h2>
                        <hr style={{
                          height: "1px",
                          margin:"auto",
                          border: "solid thin #00adcc",
                          width: "4em",
                        }}
                        >

                        </hr>

                        <h3 className="is-size-5-mobile"
                        style={{
                          color: "#00adcc"
                        }}>インターネットサービスによって今日の社会で生きづらい人の不安を減らして良い方向に導く</h3>
                      </div>
                      <div
                      >
                        <p style={{textAlign:"center", lineHeight:"2em"}}>
                        孤立や孤独がゆえに生きづらさ<br />劣等感ゆえの生きづらさ<br />トラウマが故の生きづらさ<br />他人との比較による惨めさからの生きづらさ<br />社会に適応できないことからの生きづらさ<br /> </p>
                        <p style={{textAlign:"center", lineHeight:"2em"}}>
                        他人から見れば些細なことでも、自分には大きく思えて生きづらさを感じていることがある。<br />「大したことないよ」と誰かに言われても人は簡単には変われない。<br /></p>
                        <p style={{textAlign:"center", lineHeight:"2em"}}>だけど、サービスを通じてなら自然と良い方向に導くことはできるのではないか。<br />ReThinkCSはインターネットサービスによって、今日の社会で生きづらい人を良い方向へ導くことを使命としています。
                          </p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-12"
                        style={{
                          // By using the same grid area for both, they are stacked on top of each other
                          gridArea: "1/1",
                          // This centers the other elements inside the hero component
                          placeItems: "center",
                          display: "grid",
                        }}

                      >
                        <h2>
                          サービス
                        </h2>
                        <hr style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "3em",
                          height: "1px",
                          border: "solid thin #00adcc"
                        }}
                        >

                        </hr>
                      </div>
                    </div>

                    <div className="columns is-multiline">
                      <div className="column is-6">
                        <StaticImage src="../img/appImage.jpg" alt="home-top" style={{position: "relative"}} />
                      </div>
                      <div className="column is-6"
                      >
                        <h2 className="has-text-weight-semibold">自分を成長させるSNS Ahademic (アハデミック)</h2>
                        <p>知恵を学ぶことは人を本質的に幸せにする<br />自己成長欲求が高い方が「気づき」「知恵」「アイデア」の<br />シェアを通じて成長できるナレッジコミュニティです。</p>
                        <a href="https://apps.apple.com/app/自分を成長させるsns-ahademic-アハデミック/id6474156495" className="linkbtn">
                          App Storeへ
                          </a>
                      </div>
                    </div>
                    <div style={{paddingTop:"3rem"}}>
                      <h2>会社概要</h2>
                      <table >
                    <tr>
                      <th style={{whiteSpace: "nowrap"}}>会社名</th>
                      <td>合同会社ReThinkCS</td>
                    </tr>
                    
                    <tr>
                      <th style={{whiteSpace: "nowrap"}}>代表者名</th>
                      <td>松林　公士</td>
                    </tr>
                    <tr>
                      <th style={{whiteSpace: "nowrap"}}>本社所在地</th>
                      <td>東京都渋谷区渋谷2-19-15宮益坂ビルディング609</td>
                    </tr>
                    <tr>
                      <th style={{whiteSpace: "nowrap"}}>設立日</th>
                      <td>令和4年12月16日</td>
                    </tr>
                    <tr>
                      <th style={{whiteSpace: "nowrap"}}>事業内容</th>
                      <td>「Ahademic」の運営</td>
                    </tr>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
