import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image"


export default function StaticWidthImage() {
    return (
        <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
        }}>
        <React.Fragment>
          <StaticImage src="../img/top.jpg" alt="home-top"
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              height: 400,
              // width: "100%",
            }}
          />

          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
              padding: "1rem"
            }}
          >

            <h3
              className="has-text-weight-bold is-size-5-mobile is-size-3-tablet is-size-2-widescreen"
              style={{
                // boxShadow:
                //   "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
                // backgroundColor: ",
                color: "white",
                lineHeight: "1",
                padding: "0.25rem",
                marginTop: "0.5rem"
              }}
            >
              インターネットサービスによって<br />今日の社会で生きづらい人の<br />不安を減らして良い方向に導く<br />
            </h3>
          </div>

        </React.Fragment>
      </div>
    )
}